<template>
   <div class="imageGlitch" :style="'opacity:'+eOpacity+'; background-position:'+posX+'% '+posY+'%; background-size:'+size+'px '+size+'px;'"></div>
  
</template>

<script>
export default {
  name: 'Logo',
  props: {
   
  },
   data() {
    return {
      posX:40,
      posY:40,
      eOpacity:1,
      size:1000,
    }
  },
  mounted(){
      setInterval(this.glitch,100)
      window.onresize = this.doResize;
      this.doResize()
    },
    computed:{
     
    },
    methods:{
      doResize(){
        this.size=Math.max(window.innerHeight,window.innerWidth)*1.1
      },
      glitch(){
        this.posX=20+Math.random()*60
        this.posY=20+Math.random()*60
        if(Math.random()*4>3){
          this.eOpacity=.5
        }else{
          this.eOpacity=0
        }
      }
    }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
   .imageGlitch{
  position:absolute;
  top:0px;
  left:0px;
  background-image:url('../assets/images/eye.jpg');
  width:100vw;
  height:100vh;
  opacity:0.5;
  background-size:110% calc(110vw);
  background-position: 50% 50%;
}
</style>
