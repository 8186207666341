<template>
  <div class="video" style="position:relative;height:100vh; display:flex; align-items: center;justify-content: center;">
  <div v-show="!showButton">
   <video v-if="true || showVid && currentVideo" ref="videoRef"  :src="vidUrl" :height="size+'px'"  ></video>
   
   </div>
   <ImageGlitch v-if="!vidPlaying" ref="logo" style="pointer-events:none;"></ImageGlitch>
<div class="titleHolder" v-if="currentVideo &&  showButton " @click="playVid">
  <div class="vidTitle" > {{ currentVideo.name}}</div>
 </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Logo from '@/components/Logo.vue'
import ImageGlitch from '@/components/ImageGlitch'
import LazyYoutube from "vue-lazytube";

//import gsap from gsap

export default {
  name: 'Home',
  components: {
   Logo,ImageGlitch,LazyYoutube
  },
  data() {
    return {
       videoId: 'lG0Ys-2d4MA',
      showVid:true,
      showButton:true,
     vidPlaying:false,
     video:null,
     currentVideo:null,
     vidPath:{mother:"",
              grandmother:"https://firebasestorage.googleapis.com/v0/b/vanramsey.appspot.com/o/Grandmother.mp4?alt=media&token=0ba9667f-5dd2-45f9-92bb-193df6577f42"},
     size:1000
    }
  },
  mounted(){
    this.doResize()
   
    console.log(this.$route.name,this.videos)
      window.onresize = this.doResize;
    //this.setCurrentVideo()
  },
  computed:{
    override(){
      return this.$store.getters.getOverride
    },
    vidUrl(){
      if(this.override){
        return this.override.url
      }
      if (this.currentVideo){
        return this.currentVideo.url
      }
      return null
    },
    path(){
      let url= window.location.href.split("/")
      return url[url.length-1]
      //return this.$route.name
    },
    videos(){
      return this.$store.getters.getVideos
    }
  },
  methods:{
    
    doResize(){
        this.size=window.innerHeight//Math.max(window.innerHeight,window.innerWidth)*1.1
      },
    setCurrentVideo(){
       let cVid=null
      
       console.log("this.videos",this.videos,this.path)
      if(this.path && this.videos){
       
        for(var v in this.videos){
          if(this.videos[v].id.toLowerCase()==this.path.toLowerCase()){
            cVid=this.videos[v]
            if(this.override){
              cVid = this.override
            }
          }
        }
       
      }
      if(cVid){
         console.log("cVid",cVid)
        return this.currentVideo=cVid
       
      }else{
        console.log("NO VIDEO")
        this.$router.push("-")
      }
      
    },
    playVid(event){
       this.video= this.$refs.videoRef
         console.log(this.$refs)
       //this.video=event.target
       console.log("Do PLay",this.video.paused,this.vidPlaying)
      this.showButton=false
     //this.$refs.videoRef.play(0)
      this.video.play()
      this.vidPlaying=true
       console.log("Do PLay",this.video.paused,this.vidPlaying)
    }
  },
  watch:{
    videos(val){
      if(val){

      
      console.log("VIDEOS",val)
      this.setCurrentVideo()
      }
    }
  }
}
</script>
<style scoped>
.vidTitle{
  color: #999;
  font-size:40px;
  text-transform: uppercase;
  font-weight:700;
  position:absolute;
  z-index:1000;
  cursor:pointer;
}
.titleHolder{
  width:100vw;
  height:100vh;
  position:absolute;
  display:flex;
  align-items: center;
  justify-content: center;
  
}
</style>