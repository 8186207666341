<template>
<div class="content">
  <div class="qrPage">
    <div>
   <img @click="go('mother')" class="qrCode" src="../assets/images/qr/QR-Codes-9.png" width="400px">
   </div>
   <div>
    <img @click="go('grandmother')"  class="qrCode" src="../assets/images/qr/QR-Codes-17.png" width="400px">
    </div>
  </div>
  </div>
</template>
<script>
export default {
  name: 'QR',
  props: {
   
  },
   data() {
    return {
      
    }
  },
  methods:{
    go(path){
     this.$router.push(path)
    }
  }
}
</script>
<style scoped>
.content {
    flex: 1;
    display: flex;
    height:100vh;
    overflow: auto;
     justify-content: center;

}
.qrPage{
  position:relative;
  display:flex;
  flex-wrap: wrap;


  align-items: center;
  justify-content: center;
}
.qrCode{
  margin:20px;

}
</style>
