<template>
  <div class="video" style="position:relative;height:100vh; width:100vw; display:flex; align-items: center;justify-content: center;">
  
  <vueVimeoPlayer ref="player" :video-id="videoID" @ready="onReady" :options="options" :player-height="height"></vueVimeoPlayer>	

  </div>
</template>
<script src="https://player.vimeo.com/api/player.js"></script>

<script>
// @ is an alias to /src
import Logo from '@/components/Logo.vue'
import ImageGlitch from '@/components/ImageGlitch'
import { vueVimeoPlayer } from 'vue-vimeo-player'
//

//import gsap from gsap

export default {
  name: 'Home',
  components: {
   Logo,ImageGlitch,vueVimeoPlayer
  },
  data() {
    return {
       videoId: 'lG0Ys-2d4MA',
      showVid:true,
      showButton:true,
     vidPlaying:false,
     video:null,
     currentVideo:null,
     vidPath:{mother:"",
              grandmother:"https://firebasestorage.googleapis.com/v0/b/vanramsey.appspot.com/o/Grandmother.mp4?alt=media&token=0ba9667f-5dd2-45f9-92bb-193df6577f42"},
     size:1000,
     //videoID: '737222446',
     videoID:"737233315",
			
			options:{
        background:true,
        byline:false,
        title:false,
        color:"ff0000",
      },
			playerReady: false,
      vimPlayer:null,
    }
  },
  mounted(){
    this.doResize()
   
    console.log(this.$route.name,this.videos)
      window.onresize = this.doResize;
    //this.setCurrentVideo()
    
    
  },
  computed:{
   height(){
    return window.innerHeight
   },
    path(){
      let url= window.location.href.split("/")
      return url[url.length-1]
      //return this.$route.name
    },
    videos(){
      return this.$store.getters.getVideos
    }
  },

  methods:{
    onReady() {
			this.playerReady = true
      console.log("READY",this.$refs.player)
       this.$refs.player.options.background=true
      this.$refs.player.play()
		},
		play () {
			this.$refs.player.play()
      console.log("PLAY")
		},
		pause () {
			this.$refs.player.pause()
      console.log("PAUSE")
		},
  
    doResize(){
        this.size=window.innerHeight//Math.max(window.innerHeight,window.innerWidth)*1.1
      },
    
  }
}
</script>
<style >
iframe{
  width:100vw;
}
.player{
  display: none!important;;
}
.player{
  display:none!important;
}
</style>
<style scoped>
.vidTitle{
  color: #999;
  font-size:40px;
  text-transform: uppercase;
  font-weight:700;
  position:absolute;
  z-index:1000;
  cursor:pointer;
}
.titleHolder{
  width:100vw;
  height:100vh;
  position:absolute;
  display:flex;
  align-items: center;
  justify-content: center;
  
}
</style>